import qs from 'qs';

export const getOfficesSearchUrl = ({
  companyId,
  type,
  subType,
  search,
  financialInstitutionId,
  limit = '35',
  page = '1',
  sortBy = 'name',
  withBranches,
}: {
  companyId: string;
  type?: string;
  subType?: string;
  search?: string;
  officeId?: string;
  financialInstitutionId?: string;
  limit?: string;
  page?: string;
  sortBy?: string;
  withBranches?: boolean;
}) => {
  return `/companies/${companyId}/offices?limit=${limit}&page=${page}&sort_by=${sortBy}${
    type ? `&type=${encodeURIComponent(type)}` : ''
  }${subType ? `&subtype=${encodeURIComponent(subType)}` : ''}${
    search ? `&search=${encodeURIComponent(search)}` : ''
  }${
    financialInstitutionId
      ? `&financial_institution_id=${encodeURIComponent(financialInstitutionId)}`
      : ''
  }${withBranches ? '&include_branches=true' : ''}`;
};

export const getOfficeByIdUrl = ({
  companyId,
  officeId,
}: {
  companyId: string;
  officeId: string;
}) => `/companies/${companyId}/offices/${officeId}?include_deleted=true`;

export const getOfficesUrl = ({ companyId }: { companyId: string }) => {
  return `/companies/${companyId}/offices`;
};

export const getUniversalSearchUrl = ({
  companyId,
  search,
  model = ['Person', 'Office'],
  includeDeleted = false,
  limit = '35',
}: {
  companyId: string;
  search: string;
  model?: string[];
  includeDeleted?: boolean;
  limit?: string;
}) => {
  const modalString = qs.stringify({ model }, { encode: false, indices: false });

  return `/companies/${companyId}/search?search=${encodeURIComponent(
    search
  )}&limit=${limit}&include_deleted=${includeDeleted}&${modalString}`;
};
