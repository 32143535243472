/* eslint-disable import/prefer-default-export */

import { Auth } from 'aws-amplify';
import * as AWS from 'aws-sdk';

import type { Credentials } from 'aws-sdk/lib/credentials';
import config from '../configs/config';

interface Credential {
  AccessKeyId: string;
  SecretAccessKey: string;
  SessionToken: string;
}

async function fetchTempCredentials() {
  const response = await fetch(`${config.API_DOMAIN}/auth/credentials`, {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  });

  const data = await response.json();
  return data;
}

const setAWSCred = async (tempCred: Credential) => {
  try {
    AWS.config.update({
      accessKeyId: tempCred.AccessKeyId,
      secretAccessKey: tempCred.SecretAccessKey,
      sessionToken: tempCred.SessionToken,
      region: config.AWS_REGION,
    });
  } catch (error) {
    console.error(error);
  }

  // renew the credentials when expired
  if ((AWS.config.credentials as Credentials)?.expired) {
    const renewed = await fetchTempCredentials();
    if (renewed) {
      AWS.config.update({
        accessKeyId: renewed.AccessKeyId,
        secretAccessKey: renewed.SecretAccessKey,
        sessionToken: renewed.SessionToken,
      });
      sessionStorage.setItem('TempCred', JSON.stringify(renewed));
    }
  }
};

export const updateTemporaryCredentials = async (
  forceReset?: boolean
): Promise<Credential | null | true> => {
  const savedTempCred = sessionStorage.getItem('TempCred');

  try {
    if (savedTempCred === null || forceReset) {
      sessionStorage.removeItem('TempCred');

      const data = await fetchTempCredentials();
      if (data) {
        await setAWSCred(data);
        sessionStorage.setItem('TempCred', JSON.stringify(data));
      }
    }

    if (savedTempCred && !forceReset) await setAWSCred(JSON.parse(savedTempCred));
    return await new Promise((resolve) => resolve(true));
  } catch (e) {
    return new Promise((resolve) => resolve(null));
  }
};

export const getRandomInt = (min: number, max: number) => {
  const roundedMin = Math.ceil(min);
  const roundedMax = Math.floor(max + 1);
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);

  const result = roundedMin + (array[0] / (0xffffffff + 1)) * (roundedMax - roundedMin);
  return Math.floor(result);
};

const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';

export const getRandomString = (length = 30) => {
  let result = '';

  for (let i = 0; i < length; i++) {
    result += chars[getRandomInt(0, chars.length - 1)];
  }

  return result;
};

export async function generateCodeChallenge(codeVerifier: string): Promise<string> {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const hash = await window.crypto.subtle.digest('SHA-256', data);
  const base64 = btoa(String.fromCharCode(...new Uint8Array(hash)));
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}
