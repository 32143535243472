import type { Office } from 'app/services/offices/officesApi.types';
import type { Person } from 'app/services/persons/personsApi.types';
import { DataSource } from 'constants/inputs';
import type { AutoCompleteArrayTypes, AutoCompleteTypes } from './AutoCompleteField.types';
import { getBranchesData } from '../searchFields/helpers/searchOfficesHelpers';
import type { SearchItem } from 'components/autocompleteInput/AutocompleteInput.types';
import { formatAddressForAutocomplete } from 'utils/stringUtils';

export const isPersonType = (data: Person | Office): data is Person => {
  return (<Person>data)?.individualDetails !== undefined;
};

const dataSourceTemplate = (data: AutoCompleteTypes) => {
  const isPerson = isPersonType(data);
  let office;

  if (isPerson) {
    // in the case of no office but we still need the location
    office = data?.office || { location: data.location };
  } else {
    office = data;
  }

  return {
    id: data.id,
    companyId: data.companyId,
    type: data.type,
    firstName: isPerson ? data?.individualDetails?.firstName || '' : '',
    lastName:
      (isPerson
        ? data?.individualDetails?.lastName || data?.corporationDetails?.corporationName
        : data.name) || '',
    suite: office?.location?.suite || '',
    address: office?.location?.address || '',
    city: office?.location?.city || '',
    state: office?.location?.state || '',
    postalCode: office?.location?.postalCode || '',
    country: office?.location?.country || '',
    email: (isPerson ? data?.email || office?.location?.email : office?.location?.email) || '',
    phoneNumber:
      (isPerson ? data?.phoneNumber || office?.location?.phone : office?.location?.phone) || '',
    fax: (isPerson ? data?.fax || office?.location?.fax : office?.location?.fax) || '',
    officeId: (isPerson ? data?.officeId : office?.id) || '',
    officeLocationId: office?.location?.id || '',
    incorporationNumber: (data as Person)?.corporationDetails?.incorporationNumber || '',
    gstNumber: (data as Person)?.corporationDetails?.gstNumber || '',
    nameOnDocument: (data as Person)?.corporationDetails?.nameOnDocument || '',
    authorizedSignatory: (data as Person)?.corporationDetails?.authorizedSignatory || '',
    companyOffice: data?.metadata?.companyOffice,
    branches: (data as Office)?.branches,
  };
};

type DataSourceTemplateReturnType = ReturnType<typeof dataSourceTemplate>;

function isDataSourceType(obj: any): obj is DataSourceTemplateReturnType {
  return 'display' in obj;
}

const getSourceDescription = (data: AutoCompleteTypes) => {
  let office;

  if (isPersonType(data)) {
    office = data?.office;
  } else {
    office = data;
  }

  const address = office?.location?.address || '';
  const suite = office?.location?.suite || '';
  const city = office?.location?.city || '';
  const state = office?.location?.state || '';
  const postalCode = office?.location?.postalCode || '';

  return formatAddressForAutocomplete({ address, suite, city, state, postalCode });
};

export const getSourceData = (source: AutoCompleteArrayTypes) => {
  return source.map((data) => {
    let template;

    if (isDataSourceType(data)) {
      template = data;
    } else {
      template = dataSourceTemplate(data);
    }

    return {
      ...template,
      title: template?.firstName
        ? `${template?.lastName}, ${template?.firstName}`
        : template?.lastName,
      description: getSourceDescription(data),
      display: template?.firstName
        ? `${template?.lastName}, ${template?.firstName}`
        : template?.lastName,
    };
  });
};

export type SourceData = ReturnType<typeof getSourceData>;

export const getSourceDataUnique = (source: AutoCompleteArrayTypes, withBranches?: boolean) => {
  const isBranches = source.length === 1 && (source[0] as Office)?.branches?.length;

  if (withBranches && isBranches) {
    const formatted = (source as Office[]).reduce((acc: SearchItem[], data: Office) => {
      if (data.branches) {
        return [...acc, ...getBranchesData(data.branches)];
      }
      return acc;
    }, []);
    return formatted;
  }

  const isPerson = isPersonType(source[0]);

  return [
    // Creating a new Map to guaranteed unique ids
    ...new Map(
      source.map((data) => {
        const template = dataSourceTemplate(data);
        let office: Office;
        if (isPerson) {
          office = (data as Person)?.office;
        } else {
          office = data as Office;
        }
        return [
          office?.id,
          {
            ...template,
            id: office?.id,
            title: office?.name,
            description: getSourceDescription(data),
            display: office?.name,
          },
        ];
      })
    ).values(),
  ];
};

const getChargeHolder = (data: AutoCompleteArrayTypes) => {
  if (!data) return [];

  const isPerson = isPersonType(data[0]);

  return [
    // Creating a new Map to guaranteed unique name
    ...new Map(
      data.map((d) => {
        const template = dataSourceTemplate(d);
        const { firstName = '', lastName = '' } = isPerson
          ? (d as Person)?.individualDetails || {
              firstName: '',
              lastName: (d as Person)?.corporationDetails?.corporationName,
            }
          : { firstName: '', lastName: (d as Office)?.name || '' };
        const name = firstName ? `${lastName}, ${firstName}` : lastName;

        return [
          name.toLowerCase() + d.companyId,
          {
            ...template,
            firstName,
            lastName,
            title: name,
            description: '',
            display: name,
          },
        ];
      })
    ).values(),
  ];
};

const SOURCE_MAP: Record<
  string,
  (data: AutoCompleteArrayTypes, withBranches: boolean) => Array<SearchItem>
> = {
  [DataSource.REALTOR]: (data: AutoCompleteArrayTypes) => getSourceData(data),
  [DataSource.LAWYER]: (data: AutoCompleteArrayTypes) => getSourceData(data),
  [DataSource.TAX_AUTHORITY]: (data: AutoCompleteArrayTypes) => getSourceData(data),
  [DataSource.REAL_ESTATE_AGENCY]: (data: AutoCompleteArrayTypes) => getSourceDataUnique(data),
  [DataSource.LAW_FIRM]: (data: AutoCompleteArrayTypes, withBranches: boolean) =>
    getSourceDataUnique(data, withBranches),
  [DataSource.STRATA_MANAGER]: (data: AutoCompleteArrayTypes) => getSourceData(data),
  [DataSource.STRATA]: (data: AutoCompleteArrayTypes) => getSourceDataUnique(data),
  [DataSource.CHARGE_HOLDER]: (data: AutoCompleteArrayTypes) => getChargeHolder(data),
  [DataSource.PAYEE]: (data: AutoCompleteArrayTypes) => getChargeHolder(data),
  [DataSource.GUARANTOR]: (data: AutoCompleteArrayTypes) => getChargeHolder(data),
  [DataSource.BANK]: (data: AutoCompleteArrayTypes) => getSourceData(data),
  [DataSource.BANK_PERSON]: (data: AutoCompleteArrayTypes) => getSourceData(data),
  [DataSource.BRANCH]: (data: AutoCompleteArrayTypes) => getSourceData(data),
  [DataSource.BRANCH_LENDER]: (data: AutoCompleteArrayTypes) => getSourceData(data),
  [DataSource.INSURANCE_AGENCY]: (data: AutoCompleteArrayTypes) => getSourceDataUnique(data),
  [DataSource.MHR_MANAGEMENT]: (data: AutoCompleteArrayTypes) => getSourceDataUnique(data),
};

export const buildDataSource = (
  dataSource: string,
  data: AutoCompleteArrayTypes,
  withBranches = false
) => {
  if (SOURCE_MAP[dataSource]) {
    return SOURCE_MAP[dataSource](data, withBranches);
  }
  return [];
};
