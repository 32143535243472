import { DEFAULT_DATE_FORMAT, TEXT_DATE_FORMAT } from 'constants/formats';
import {
  addDays,
  differenceInCalendarDays,
  formatISO,
  isValid,
  parse,
  parseISO,
  subDays,
} from 'date-fns';
import format from 'date-fns/format';
import {
  endOfMonth,
  endOfQuarter,
  endOfYear,
  startOfMonth,
  startOfQuarter,
  startOfYear,
} from 'date-fns/fp';

/**
 * Mask email address
 * 'example@test.com' => 'e*****e@test.com'
 * @param email string
 * @returns masked email address
 */
export const maskEmail = (email: string) =>
  email.replace(/^(.)(.*)(.@.*)$/, (_, a, b, c) => a + b.replace(/./g, '*') + c);

export const formatDateTS = (date: Date | number, dateFormat = DEFAULT_DATE_FORMAT): string => {
  if (!date) {
    return '';
  }

  return date ? format(date, dateFormat) : '';
};

export const parseFormatDate = (date: string, dateFormat = DEFAULT_DATE_FORMAT): string => {
  if (!date) {
    return '';
  }

  let dateValue;
  try {
    dateValue = parseISO(date);
  } catch (error) {
    console.info('Failed to parse date:', error, date);
    dateValue = Date.parse(date);
  }

  if (!isValid(dateValue)) {
    dateValue = parse(date, 'PPP', new Date());
  }

  return dateValue ? format(dateValue, dateFormat) : '';
};

export const formatDateISO = (date: string) => {
  if (!date) {
    return '';
  }

  const dateValue = parseISO(date);
  return dateValue ? formatISO(dateValue) : '';
};

export const formatDate = (date: string, dateFormat = DEFAULT_DATE_FORMAT): string => {
  if (!date) {
    return '';
  }

  const dateValue = date.split('T')?.[0];
  return dateValue ? format(parseISO(dateValue), dateFormat) : '';
};

export const formatDateTime = (date: string, dateFormat = DEFAULT_DATE_FORMAT): string => {
  if (!date) {
    return '';
  }

  return format(parseISO(date), dateFormat);
};

export const formatDateWithTime = (date: string): string => {
  return format(parseISO(date), TEXT_DATE_FORMAT);
};

/**
 * calculate number of days between two dates
 * @param dateLeft the later date
 * @param dateRight the earlier date
 * @returns number
 */
export const calculateNumberOfDays = (dateLeft: string, dateRight: string): number => {
  return differenceInCalendarDays(new Date(dateLeft), new Date(dateRight));
};

export const formatCurrencyAmount = (amount: number) => {
  if (amount === undefined || amount === null || Number.isNaN(+amount)) {
    return '';
  }

  return new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD',
  }).format(amount);
};

export const buildOptionsFromEnum = <EnumType extends Record<string, string>>(
  enumConst: EnumType
) =>
  Object.keys(enumConst).map((key) => ({
    key,
    value: key,
    text: enumConst[key],
  }));

export const getPersonName = ({
  firstName,
  middleName,
  lastName,
}: {
  firstName?: string;
  middleName?: string;
  lastName?: string;
}) => {
  if (firstName && middleName && lastName) {
    return `${firstName} ${middleName} ${lastName}`;
  }

  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  if (firstName) {
    return firstName;
  }

  return lastName || '';
};

export const formatDateAddDays = (
  date: string,
  daysNum: number,
  dateFormat = DEFAULT_DATE_FORMAT
): string => {
  if (!date) {
    return '';
  }

  const dateValue = parseISO(date);
  const addedDays = addDays(dateValue, daysNum);

  return format(addedDays, dateFormat);
};

export const formatDateSubDays = (
  date: string,
  daysNum: number,
  dateFormat = DEFAULT_DATE_FORMAT
): string => {
  if (!date) {
    return '';
  }

  const dateValue = parseISO(date);
  const subbedDays = subDays(dateValue, daysNum);

  return format(subbedDays, dateFormat);
};

export const getDaysInYear = (year: string | number): number => {
  const newYear = typeof year === 'string' ? Number(year) : year;
  return newYear % 4 === 0 && (newYear % 100 !== 0 || newYear % 400 === 0) ? 366 : 365;
};

export const formatDateReplacedSlashes = (date: string): string => {
  return format(new Date(date.replace(/-/g, '/')), 'PP');
};

export const formatStartOfMonth = (date?: string, dateFormat = DEFAULT_DATE_FORMAT) => {
  if (date) {
    return format(startOfMonth(new Date(date)), dateFormat);
  }
  return format(startOfMonth(new Date()), dateFormat);
};

export const formatEndOfMonth = (date?: string, dateFormat = DEFAULT_DATE_FORMAT) => {
  if (date) {
    return format(endOfMonth(new Date(date)), dateFormat);
  }

  return format(endOfMonth(new Date()), dateFormat);
};

export const formatStartOfYear = (date?: string, dateFormat = DEFAULT_DATE_FORMAT) => {
  if (date) {
    return format(startOfYear(new Date(date)), dateFormat);
  }

  return format(startOfYear(new Date()), dateFormat);
};

export const formatEndOfYear = (date?: string, dateFormat = DEFAULT_DATE_FORMAT) => {
  if (date) {
    return format(endOfYear(new Date(date)), dateFormat);
  }

  return format(endOfYear(new Date()), dateFormat);
};

export const formatStartOfQuarter = (date?: string, dateFormat = DEFAULT_DATE_FORMAT) => {
  if (date) {
    return format(startOfQuarter(new Date(date)), dateFormat);
  }

  return format(startOfQuarter(new Date()), dateFormat);
};

export const formatEndOfQuarter = (date?: string, dateFormat = DEFAULT_DATE_FORMAT) => {
  if (date) {
    return format(endOfQuarter(new Date(date)), dateFormat);
  }

  return format(endOfQuarter(new Date()), dateFormat);
};

export const getNameIndex = (name: string) => {
  const index = name.substring(name.indexOf('[') + 1, name.lastIndexOf(']'));
  return index;
};

export const getNamePrefix = (name: string) => {
  const index = getNameIndex(name);
  const namePrefix = index ? `[${index}].` : '';
  return namePrefix;
};

export const isUpperCase = (str: string) => {
  return str === str.toUpperCase();
};

export const replaceUnderScoreWithSpace = (str: string) => {
  return str.replace(/_/g, ' ');
};

export const currencyStringToFloat = (currencyString: string) => {
  const exp = /[^0-9.-]+/g;

  const value = currencyString.replace(exp, '') || '0.00';
  return Number.parseFloat(value);
};

export const formatAddressForAutocomplete = ({
  address,
  city,
  suite,
  state,
  postalCode,
}: {
  address: string;
  city: string;
  suite: string;
  state: string;
  postalCode: string;
}) => {
  return `${address} ${suite ? `${suite},` : ''} ${city ? `${city},` : ''} ${state} ${postalCode}`;
};

export const formatCivicAddress = ({
  address,
  city,
  suite,
}: {
  address?: string;
  city?: string;
  suite?: string;
}) => {
  return `${suite ? `${suite} -` : ''} ${city ? `${address}, ${city}` : address}`;
};

export const formatCivicAddressExtended = ({
  address,
  city,
  suite,
  postalCode,
  state,
}: {
  address?: string;
  city?: string;
  suite?: string;
  state: string;
  postalCode: string;
}) => {
  const addressWithCity = city ? `${address}, ${city}` : address;

  return `${suite ? `${suite} -` : ''} ${
    addressWithCity ? `${addressWithCity},` : ''
  } ${state} ${postalCode}`;
};

export function commaDelimitedNamesWithAnd(names: Array<string>): string {
  const cleaned = names.filter((item) => item);
  if (cleaned.length === 0) return '';
  if (cleaned.length === 1) return cleaned[0];

  return [
    ...cleaned.slice(0, cleaned.length - 2),
    `${cleaned[cleaned.length - 2]} and ${cleaned[cleaned.length - 1]}`,
  ].join(', ');
}

export function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const removeSpaces = (str: string) => {
  return str?.replace(/\s/g, '')?.trim() || '';
};
