import { apiSlice } from '../apiSlice';
import { Tags, providesList, providesTag } from '../tags';
import {
  getPersonsUrl,
  getPersonByIdUrl,
  getPersonsByOfficeIdUrl,
  getPersonMatchUrl,
  getPersonUrl,
} from './helpers';
import type {
  Person,
  PersonsQueryArgs,
  PersonsResponse,
  PersonQueryArgs,
  PersonsByOfficeIdQueryArgs,
  PersonMatchArgs,
  LinkPersonMatchArgs,
} from './personsApi.types';

export const personApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getPersons: build.query<Person[], PersonsQueryArgs>({
      query: (arg) => ({ url: getPersonsUrl(arg), method: 'GET' }),
      transformResponse(response: PersonsResponse) {
        return response?.data;
      },
      providesTags: (result) => providesList(result, Tags.PERSON),
    }),

    getPersonById: build.query<Person, PersonQueryArgs>({
      query: ({ companyId, personId }) => ({
        url: getPersonByIdUrl({ companyId, personId }),
        method: 'GET',
      }),
      providesTags: (_result, _error, { personId }) => providesTag(personId, Tags.PERSON),
    }),

    getPersonsByOfficeId: build.query<Person[], PersonsByOfficeIdQueryArgs>({
      query: ({ companyId, officeId }) => ({
        url: getPersonsByOfficeIdUrl({ companyId, officeId }),
        method: 'GET',
      }),
      providesTags: (result) => providesList(result, Tags.PERSON),
    }),

    updatePersonMatch: build.mutation<unknown, PersonMatchArgs>({
      query: ({ companyId, matterId, matterPersons }) => {
        return {
          url: getPersonMatchUrl({ companyId, matterId }),
          method: 'PATCH',
          data: {
            ...matterPersons,
          },
        };
      },
      invalidatesTags: [
        { type: Tags.SELLER, id: Tags.LIST },
        { type: Tags.BUYER, id: Tags.LIST },
        { type: Tags.BUYER_TENANCY, id: Tags.LIST },
        { type: Tags.BORROWER, id: Tags.LIST },
      ],
    }),

    linkPersonToMater: build.mutation<unknown, LinkPersonMatchArgs>({
      query: ({ companyId, matterId, personId, type }) => ({
        url: getPersonUrl({ companyId, matterId }),
        method: 'POST',
        data: {
          personId,
          type,
        },
      }),
      invalidatesTags: [
        { type: Tags.SELLER, id: Tags.LIST },
        { type: Tags.BUYER, id: Tags.LIST },
        { type: Tags.BUYER_TENANCY, id: Tags.LIST },
        { type: Tags.BORROWER_TENANCY, id: Tags.LIST },
        { type: Tags.BORROWER, id: Tags.LIST },
      ],
    }),
  }),
});

export const {
  useGetPersonsQuery,
  useGetPersonByIdQuery,
  useGetPersonsByOfficeIdQuery,
  useUpdatePersonMatchMutation,
  useLinkPersonToMaterMutation,
} = personApi;
