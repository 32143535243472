import { createApi, retry } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './customBaseQuery';
import { Tags } from './tags';

// Create our baseQuery instance
const baseQuery = axiosBaseQuery();
const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });

export const apiSlice = createApi({
  baseQuery: baseQueryWithRetry,
  tagTypes: [
    Tags.ACCOUNT_LIBRARY,
    Tags.CALENDAR,
    Tags.CUSTOMER,
    Tags.CLIENT,
    Tags.MATTER,
    Tags.MATTER_BY_RANGE,
    Tags.TITLE_SEARCH,
    Tags.TITLE_SEARCH_ORDER,
    Tags.PROPERTY,
    Tags.PERSON,
    Tags.DOCUMENT,
    Tags.CHARGE,
    Tags.SELLER,
    Tags.BUYER,
    Tags.FORM_TYPE,
    Tags.PACKAGE,
    Tags.LOTR_PACKAGE,
    Tags.CHARGE_DEBIT_CREDIT,
    Tags.TEMPLATE,
    Tags.FEE,
    Tags.DISBURSEMENT,
    Tags.OFFICE,
    Tags.FEE_MATTER,
    Tags.DISBURSEMENT_MATTER,
    Tags.MANAGE_USER,
    Tags.USER,
    Tags.ACCOUNT_SETTING,
    Tags.AUTO_COMPLETE,
    Tags.LIBRARY_DEBIT_CREDIT,
    Tags.SEARCH,
    Tags.MATTER_SEARCH,
    Tags.GROUP_TEMPLATES,
    Tags.GROUP_TEMPLATES_SEARCH,
    Tags.GROUP_TEMPLATES_SEARCH_MATTER,
    Tags.INSURANCE,
    Tags.MORTGAGE,
    Tags.MORTGAGE_LENDER,
    Tags.LENDER_PRODUCT,
    Tags.MORTGAGE_PRODUCT,
    Tags.MORTGAGE_PRODUCT_TERMS,
    Tags.GUARANTOR,
    Tags.BORROWER,
    Tags.ADJUSTMENTS_LIBRARY,
    Tags.ADJUSTMENTS,
    Tags.STATEMENT_ADJUSTMENTS,
    Tags.TRUST,
    Tags.TRUST_ACCOUNTS,
    Tags.CONTACT,
    Tags.CTIC_TITLE_INSURANCE,
    Tags.CTIC_URL,
    Tags.CTIC_DOCUMENTS,
    Tags.NOTIFICATION,
    Tags.NOTES,
    Tags.TITLE_INSURANCE,
    Tags.TITLE_INSURANCE_URL,
    Tags.TASKS,
    Tags.MATTER_TASKS,
    Tags.UNDERTAKING,
    Tags.UNDERTAKING_TEMPLATE,
    Tags.CHECKLISTS,
    Tags.CHECKLIST_TASKS,
    Tags.CHECKLIST_TEMPLATES,
    Tags.BORROWER_TENANCY,
    Tags.BUYER_TENANCY,
    Tags.IMPORT,
  ],
  endpoints: () => ({}),
});
