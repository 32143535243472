import { createSlice } from '@reduxjs/toolkit';
import type { Filter } from 'app/interfaces';
import type { RootState } from 'app/rootReducer';
import { autoCompleteApi } from 'app/services/autoComplete/autoCompleteApi';
import { personApi } from 'app/services/persons/personsApi';
import type { AutoCompleteArrayTypes } from 'components/forms/fields/autoCompleteField/AutoCompleteField.types';
import {
  getSourceData,
  type SourceData,
} from 'components/forms/fields/autoCompleteField/dataSourceUtils';
import { MatterPersonTypes } from 'constants/inputs';

const LEGAL_PERSON = {
  personId: '',
  officeId: '',
  email: '',
  fax: '',
  phoneNumber: '',
  suite: '',
  address: '',
  city: '',
  state: '',
  postalCode: '',
  country: '',
  conveyancer: '',
  conveyancerEmail: '',
};

const REALTOR_PERSON = {
  officeId: '',
  name: '',
  commission: '',
  gst: '',
  totalCommission: '',
  email: '',
  fax: '',
  phoneNumber: '',
  suite: '',
  address: '',
  city: '',
  state: '',
  postalCode: '',
  country: '',
  showOnOtp: false,
  populateSeller: false,
};

export const initialValues = {
  fileNumber: '',
  dates: {
    completionDate: '',
    adjustmentDate: '',
    possessionDate: '',
    contractDate: '',
    createdDate: '',
  },
  sellerRepresentative: LEGAL_PERSON,
  buyerRepresentative: LEGAL_PERSON,
  sellerRealtor: REALTOR_PERSON,
  buyerRealtor: REALTOR_PERSON,
  deposit: { depositAmount: '', paidTo: '' },
  excessDeposit: { depositAmount: '', paidTo: '' },
};

export const refinanceInitialValues = {
  fileNumber: '',
  dates: {
    completionDate: '',
    createdDate: '',
  },
  borrowerRepresentative: LEGAL_PERSON,
  lenderRepresentative: LEGAL_PERSON,
};

export const purchaseInitialValues = {
  ...initialValues,
  sellerRealtor: { ...REALTOR_PERSON, debitTo: MatterPersonTypes.SELLER },
  buyerRealtor: { ...REALTOR_PERSON, debitTo: MatterPersonTypes.SELLER },
};

export type DetailsFormValues = typeof initialValues;

type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export type SourceObj = ArrayElement<SourceData>;

export interface CachedData {
  [personId: string]: SourceObj;
}

interface DetailsUIState {
  filter: {
    [key: string]: Filter;
  };
  personsCache: { [personId: string]: SourceData };
  officesAutocompleteCache: CachedData;
  personsAutocompleteCache: CachedData;
}

const initialState: DetailsUIState = {
  filter: {},
  personsCache: {},
  officesAutocompleteCache: {},
  personsAutocompleteCache: {},
};

export const detailsSlice = createSlice({
  name: 'details',
  initialState,
  reducers: {
    clearPersonsCache: (state) => {
      state.personsCache = {};
    },
    setFilter: (state, action) => {
      const { type, filter } = action.payload;

      state.filter[type] = filter;
    },
    clearFilter: (state) => {
      state.filter = {};
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      personApi.endpoints.getPersonsByOfficeId.matchFulfilled,
      (state, { payload }) => {
        const dataSource = getSourceData(payload);

        dataSource.forEach((d) => {
          state.personsCache[d?.id] = d as unknown as SourceData;
        });
      }
    );

    builder.addMatcher(
      autoCompleteApi.endpoints.getPersonsSelect.matchFulfilled,
      (state, { payload }) => {
        const dataSource = getSourceData(payload as unknown as AutoCompleteArrayTypes);

        dataSource.forEach((d) => {
          state.personsAutocompleteCache[d?.id] = d;
        });
      }
    );
    builder.addMatcher(
      autoCompleteApi.endpoints.getPersonByIdSelect.matchFulfilled,
      (state, { payload }) => {
        const person = getSourceData([payload] as unknown as AutoCompleteArrayTypes);
        state.personsAutocompleteCache[person[0]?.id] = person[0];
      }
    );

    builder.addMatcher(
      autoCompleteApi.endpoints.getOfficesSelect.matchFulfilled,
      (state, { payload }) => {
        const dataSource = getSourceData(payload as unknown as AutoCompleteArrayTypes);

        dataSource.forEach((d) => {
          state.officesAutocompleteCache[d?.id] = d;
        });
      }
    );
    builder.addMatcher(
      autoCompleteApi.endpoints.getOfficeByIdSelect.matchFulfilled,
      (state, { payload }) => {
        const office = getSourceData([payload] as unknown as AutoCompleteArrayTypes);
        state.officesAutocompleteCache[office[0]?.id] = office[0];
      }
    );
  },
});

export const { setFilter, clearFilter } = detailsSlice.actions;

export const selectDetails = (state: RootState): DetailsUIState => state.details;

export default detailsSlice.reducer;
