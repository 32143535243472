import type { CognitoUser } from '@aws-amplify/auth';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useAppDispatch } from 'app/store';
import { Auth } from 'aws-amplify';
import { useMemo } from 'react';

// in-memory cache
// typing based on amplify
let userCache: CognitoUser | any;

type Cache = typeof userCache;

const setUserCache = (cache: Cache): void => {
  userCache = cache;
};

export const useAuth = () => {
  const dispatch = useAppDispatch();
  const { user } = useAuthenticator((context) => [context.user]);
  const { route } = useAuthenticator((context) => [context.route]);

  const signOut = async () => {
    try {
      await Auth.signOut();
      sessionStorage.clear();
      dispatch({ type: 'login/logout' });
    } catch (signOutError) {
      console.error(signOutError);
    }
  };

  return useMemo(
    () => ({
      companyId: user?.attributes?.['custom:company_id'] ?? '',
      isUserAdmin: user?.attributes?.['custom:role'] === 'go-admin',
      user,
      signOut,
      authState: route,
      userCache,
      setUserCache,
    }),

    [user, route, userCache]
  );
};
