import config from './config';

const awsAmplifyConfig = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: config.AWS_REGION,

    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
};

export default awsAmplifyConfig;
