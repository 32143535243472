import {
  type AnyAction,
  combineReducers,
  type ActionFromReducer,
  type Reducer,
} from '@reduxjs/toolkit';
import { apiSlice } from './services/apiSlice';
import searchReducer from 'components/topNav/searchSlice';
import accordionReducer from 'components/accordion/floAccordionSlice';
import modalReducer from 'components/modal/modalSlice';
import detailsReducer from 'features/details/detailsSlice';
import rightSidebarReducer from 'components/sidebars/rightSidebar/rightSidebarSlice';

const combinedReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  search: searchReducer,
  accordion: accordionReducer,
  modal: modalReducer,
  details: detailsReducer,
  rightSidebar: rightSidebarReducer,
});

export type RootReducerState = ReturnType<typeof combinedReducer>;
export type RootReducerAction = ActionFromReducer<typeof combinedReducer>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const rootReducerWrapper = (
  state: RootReducerState,
  action: AnyAction
): ReturnType<typeof combinedReducer> => {
  if (action.type === 'login/logout') {
    return combinedReducer(undefined, action);
  }

  return combinedReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducerWrapper>;

const rootReducer = rootReducerWrapper as Reducer<RootReducerState, RootReducerAction>;

export default rootReducer;
