export enum DataSource {
  REALTOR = 'REALTOR',
  REAL_ESTATE_AGENCY = 'REAL_ESTATE_AGENCY',
  LAWYER = 'LAWYER',
  LAW_FIRM = 'LAW_FIRM',
  BANKER = 'BANKER',
  BANK = 'BANK',
  BRANCH = 'BRANCH',
  STRATA_MANAGER = 'STRATA_MANAGER',
  STRATA = 'STRATA',
  CHARGE_HOLDER = 'CHARGE_HOLDER',
  USERS = 'USERS',
  CHECKLIST_TEMPLATES = 'CHECKLIST_TEMPLATES',
  CHECKLISTS = 'CHECKLISTS',
  PAYEE = 'PAYEE',
  INSURANCE_AGENCY = 'INSURANCE_AGENCY',
  BANK_PERSON = 'BANK_PERSON',
  GUARANTOR = 'GUARANTOR',
  TAX_AUTHORITY = 'TAX_AUTHORITY',
  BRANCH_LENDER = 'BRANCH_LENDER',
  USER_CONVEYANCER = 'USER_CONVEYANCER',
  MHR_MANAGEMENT = 'MHR_MANAGEMENT',
}

export enum FirmTypes {
  LEGAL = 'legal',
  FINANCIAL = 'financial',
}

export enum PersonTypes {
  INDIVIDUAL = 'individual',
  CORPORATE = 'corporate',
  ALL = 'all',
}

export enum MatterPersonTypes {
  SELLER = 'SELLER',
  BUYER = 'BUYER',
  BORROWER = 'BORROWER',
  LENDER = 'LENDER',
  REALTOR_SELLER = 'REALTOR_SELLER',
  REALTOR_BUYER = 'REALTOR_BUYER',
  LEGAL_SELLER = 'LEGAL_SELLER',
  LEGAL_BUYER = 'LEGAL_BUYER',
  LEGAL_BORROWER = 'LEGAL_BORROWER',
  LEGAL_LENDER = 'LEGAL_LENDER',
}

export enum ChargeTypes {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
}

export enum OfficeType {
  LEGAL = 'LEGAL',
  FINANCIAL_INSTITUTION = 'FINANCIAL_INSTITUTION',
  REALTOR = 'REALTOR',
  INSURANCE = 'INSURANCE',
  STRATA = 'STRATA',
  CHARGE_HOLDER = 'CHARGE_HOLDER',
  CLIENT = 'CLIENT',
  TAX_AUTHORITY = 'TAX_AUTHORITY',
  MHR_MANAGEMENT = 'MHR_MANAGEMENT',
}

export enum OfficeSubtype {
  NOTARY = 'NOTARY',
  LAWYER = 'LAWYER',
  LAND_TITLE_OFFICE = 'LAND_TITLE_OFFICE',
  STRATA_MANAGER = 'STRATA_MANAGER',
  HEAD_OFFICE = 'HEAD_OFFICE',
  BRANCH = 'BRANCH',
}

export enum ContactType {
  FIRM_OFFICE = 'firmOffice',
  LAWYER_NOTARY = 'lawyerNotary',
}

export enum ContactScope {
  GLOBAL = 'global',
  COMPANY = 'company',
}

export enum MatterInsuranceCoverageTypes {
  REPLACEMENT = 'REPLACEMENT',
  UNLIMITED = 'UNLIMITED',
  ENTER_AMOUNT = 'ENTER_AMOUNT',
}

export enum InsuranceType {
  HOME_INSURANCE = 'HOME_INSURANCE',
  TITLE_INSURANCE = 'TITLE_INSURANCE',
}

export enum TitleInsuranceCompany {
  CHICAGO_TITLE_INSURANCE_COMPANY = 'Chicago Title Insurance Company',
  STEWART_TITLE_GUARANTY_COMPANY = 'Stewart Title Guaranty Company',
  FCT_INSURANCE_COMPANY_LTD = 'FCT Insurance Company Ltd.',
}

export enum DepositPaidTo {
  SELLER_LISTING_REALTOR = 'sellerListingRealtor',
  BUYER_SELLING_REALTOR = 'buyerSellingRealtor',
  BUYER_REPRESENTATIVE = 'buyerRepresentative',
  SELLER_REPRESENTATIVE = 'sellerRepresentative',
  SELLER = 'seller',
}

export enum AdjustmentTypes {
  PROPERTY_TAX = 'PROPERTY_TAX',
  OTHER = 'OTHER',
  TENANCY = 'TENANCY',
  UTILITY = 'UTILITY',
  PRORATED = 'PRORATED',
  STRATA = 'STRATA',
  PAD_RENTAL = 'PAD_RENTAL',
}

export const YES_NO_RADIO = [
  ['common.yes', 'common.yes'],
  ['common.no', 'common.no'],
];

export enum ThirdPartyConnections {
  LTSA = 'LTSA',
  SOLUNO = 'Soluno',
  STEWART_TITLE = 'Stewart Title',
  FCT_TITLE = 'FCT_TITLE',
}

export enum IDTypes {
  PASSPORT = 'PASSPORT',
  DRIVER_LICENSE = 'DRIVER_LICENSE',
  NEXUS_CARD = 'NEXUS_CARD',
  PERMANENT_RESIDENT_CARD = 'PERMANENT_RESIDENT_CARD',
  OTHER = 'OTHER',
}

export enum GSTChargedTypes {
  NO_GST = 'noGst',
  GST_NOT_INCLUDED = 'gstNotIncluded',
  GST_INCLUDED = 'gstIncluded',
  BUYER_IS_REGISTERED = 'buyerIsRegistered',
}
