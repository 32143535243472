import { useAuth } from 'hooks';

export function PrivateRoute({ children }: { children: JSX.Element }) {
  const { authState } = useAuth();

  if (authState !== 'authenticated' && authState !== 'idle') {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.

    // return <Navigate to="/login" state={{ from: location }} />;

    window.location.replace('/login');
    return <></>;
  }

  return children;
}
