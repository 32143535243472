import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'app/rootReducer';

interface ModalState {
  openModal: string | null;
  modalProps?: string | null;
}

const initialState: ModalState = { openModal: null, modalProps: null };

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action) => {
      const { payload } = action;
      state.openModal = payload;
    },
    closeModal: (state) => {
      state.openModal = null;
      state.modalProps = null;
    },
    setModalProps: (state, action) => {
      const { payload } = action;
      state.modalProps = payload;
    },
  },
});

export const { openModal, closeModal, setModalProps } = modalSlice.actions;

export const selectModal = (state: RootState): ModalState => state.modal;

export default modalSlice.reducer;
