export const MATTER_COMMON_URLS: Record<string, string> = {
  titleSearch: '/matters/:matterId/:type/title-search',
  property: '/matters/:matterId/:type/property',
  details: '/matters/:matterId/:type/details',
  sellers: '/matters/:matterId/:type/sellers',
  buyers: '/matters/:matterId/:type/buyers',
  charges: '/matters/:matterId/:type/charges',
  documents: '/matters/:matterId/:type/documents',
  debitCredit: '/matters/:matterId/:type/debit-credit',
  accounts: '/matters/:matterId/:type/accounts',
  trust: '/matters/:matterId/:type/trust',
  soluno: '/matters/:matterId/:type/soluno-trust',
  webFiling: '/matters/:matterId/:type/web-filing',
  lotrFiling: '/matters/:matterId/:type/lotr-filing',
  titleInsurance: '/matters/:matterId/:type/title-insurance',
  notes: '/matters/:matterId/:type/notes',
  undertakings: '/matters/:matterId/:type/undertakings',
};

export const MATTER_REFINANCE_URLS: Record<string, string> = {
  borrowers: '/matters/:matterId/:type/borrowers',
  mortgage: '/matters/:matterId/:type/mortgage',
  insurance: '/matters/:matterId/:type/insurance',
};

export const MATTER_PURCHASE_URLS: Record<string, string> = {
  adjustments: '/matters/:matterId/:type/adjustments',
  statementOfAdjustments: '/matters/:matterId/:type/statement-of-adjustments',
};

export const MATTER_URLS: Record<string, string> = {
  ...MATTER_COMMON_URLS,
  ...MATTER_REFINANCE_URLS,
  ...MATTER_PURCHASE_URLS,
};

export const USER_URLS = {
  users: '/users',
  userProfile: '/users/user-profile',
  documentGroups: '/users/document-groups',
  accountLibrary: '/users/account-library',
  checklistLibrary: '/users/checklists',
  undertakingsLibrary: '/users/undertakings-library',
  trustAccounts: '/users/trust-accounts',
  adjustmentLibrary: '/users/adjustment-library',
  accountSettings: '/users/account-settings',
  manageUsers: '/users/manage-users',
  debitCreditLibrary: '/users/debit-credit-library',
};

export const CONTACTS_URLS = {
  clients: '/contacts/clients',
  contacts: '/contacts',
  legal: '/contacts/legal',
  realEstateOffices: '/contacts/real-estate-offices',
  insuranceAgencies: '/contacts/insurance-agencies',
  lenders: '/contacts/lenders',
  strataOffices: '/contacts/strata-offices',
  taxationAuthority: '/contacts/taxation-authority',
  mhrManagementCompany: '/contacts/mhr-management-company',
};

const CALLBACK_URLS = {
  oauth: '/oauth',
  stewart: '/oauth/stewart/success',
};

export const PAGE_URLS: Record<string, string> = {
  baseurl: '/',
  layout: '/layout',
  matters: '/matters',
  search: '/search',
  calendar: '/calendar',
  tasks: '/tasks',
  ...MATTER_URLS,
  ...USER_URLS,
  ...CONTACTS_URLS,
  ...CALLBACK_URLS,
};
