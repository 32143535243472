import { type MiddlewareAPI, isRejectedWithValue, type Middleware } from '@reduxjs/toolkit';
import { toast } from 'react-hot-toast';

export const SKIP_TOAST = [
  'No property for this Matter',
  'Cannot find Template',
  'Cannot find Property',
  'Cannot find Adjustment',
];

export const skipToast = (message: string) => {
  return (
    SKIP_TOAST.includes(message) ||
    message.includes('not found') ||
    message.includes('Unable to get a new secure url')
  );
};

/**
 * Log a warning and show a toast!
 * Also intercepts 401 and redirect to login
 */
export const rtkQueryErrorMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  const { dispatch } = api;

  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
  if (isRejectedWithValue(action)) {
    console.log('payload=>', action.payload);
    if (action.payload.status === 401) {
      dispatch({ type: 'login/logout' });
    }

    if (action.payload.status === 404 && skipToast(action.payload.data)) {
      return next(action);
    }

    console.warn('Server error response:', action.error.message);

    const errorMessage = action.payload?.data || action.error.message;
    if (typeof errorMessage === 'string') toast.error(errorMessage);
  }

  return next(action);
};
