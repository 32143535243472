import { buildDataSource } from 'components/forms/fields/autoCompleteField/dataSourceUtils';
import { createSelector } from '@reduxjs/toolkit';
import { apiSlice } from '../apiSlice';
import { getOfficeByIdUrl, getOfficesSearchUrl, getUniversalSearchUrl } from '../offices/helpers';
import type { Office, OfficesResponse } from '../offices/officesApi.types';
import { getPersonByIdUrl, getPersonsUrl } from '../persons/helpers';
import type { Person, PersonsResponse } from '../persons/personsApi.types';
import { providesList, providesTag, Tags } from '../tags';
import type {
  AutoCompleteByOfficeIdQueryArgs,
  AutoCompleteByPersonIdQueryArgs,
  AutoCompleteQueryArgs,
  AutoCompleteUniversalQueryArgs,
} from './autoCompleteApi.types';
import type { SearchItem } from 'components/autocompleteInput/AutocompleteInput.types';

export const autoCompleteApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getUniversalSelect: build.query<SearchItem[], AutoCompleteUniversalQueryArgs>({
      query: (arg) => ({ url: getUniversalSearchUrl(arg), method: 'GET' }),
      transformResponse(
        response: { offices: OfficesResponse; persons: PersonsResponse },
        _meta,
        { dataSource }
      ) {
        const { persons, offices } = response;
        const personsData = buildDataSource(dataSource, persons?.data);
        const officesData = buildDataSource(dataSource, offices?.data);

        return [...personsData, ...officesData];
      },
      providesTags: (result) => providesList(result, Tags.AUTO_COMPLETE),
    }),

    getOfficesSelect: build.query<SearchItem[], AutoCompleteQueryArgs>({
      query: (arg) => ({ url: getOfficesSearchUrl(arg), method: 'GET' }),
      transformResponse(response: OfficesResponse, _meta, { dataSource, withBranches }) {
        return buildDataSource(dataSource, (response as OfficesResponse)?.data, withBranches);
      },
      providesTags: (result) => providesList(result, Tags.AUTO_COMPLETE),
    }),

    getOfficeByIdSelect: build.query<SearchItem[], AutoCompleteByOfficeIdQueryArgs>({
      query: ({ companyId, officeId }) => ({
        url: getOfficeByIdUrl({ companyId, officeId }),
        method: 'GET',
      }),
      transformResponse(response: Office, _meta, { dataSource, withBranches }) {
        if (withBranches) {
          return buildDataSource(dataSource, [response], withBranches);
        }
        return [buildDataSource(dataSource, [response])[0]];
      },
      providesTags: (_result, _error, { officeId }) => providesTag(officeId, Tags.AUTO_COMPLETE),
    }),

    getPersonsSelect: build.query<SearchItem[], AutoCompleteQueryArgs>({
      query: ({ dataSource, ...arg }) => ({ url: getPersonsUrl(arg), method: 'GET' }),
      transformResponse(response: PersonsResponse, _, { dataSource }) {
        return buildDataSource(dataSource, response?.data);
      },
      providesTags: (result) => providesList(result, Tags.AUTO_COMPLETE),
    }),

    getPersonByIdSelect: build.query<SearchItem, AutoCompleteByPersonIdQueryArgs>({
      query: ({ companyId, personId }) => ({
        url: getPersonByIdUrl({ companyId, personId }),
        method: 'GET',
      }),
      transformResponse(response: Person, _meta, { dataSource }) {
        return buildDataSource(dataSource, [response])[0];
      },
      providesTags: (_result, _error, { personId }) => providesTag(personId, Tags.AUTO_COMPLETE),
    }),
  }),
});

export const {
  useLazyGetPersonsSelectQuery,
  useLazyGetOfficesSelectQuery,
  useGetUniversalSelectQuery,
  useGetOfficesSelectQuery,
  useGetOfficeByIdSelectQuery,
  useGetPersonByIdSelectQuery,
  useGetPersonsSelectQuery,
} = autoCompleteApi;

export const selectChargeHolderName = ({
  companyId,
  dataSource,
  personId,
}: {
  companyId: string;
  personId: string;
  dataSource: any;
}) =>
  createSelector(
    [
      autoCompleteApi.endpoints.getPersonByIdSelect.select({
        companyId,
        personId,
        dataSource,
      }),
    ],
    ({ data }) => {
      return data?.display ?? '';
    }
  );
