import './init';
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { Amplify } from 'aws-amplify';
import awsAmplifyConfig from 'configs/aws';

import { store } from 'app/store';
import App from 'app/App';
// import reportWebVitals from './reportWebVitals';
import 'fomantic-ui-less/semantic.less';
import './styles/index.less';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import { PrivateRoute } from 'utils/PrivateRoute';

import './i18n';
import { ToastBar, Toaster } from 'react-hot-toast';
import { t } from 'i18next';
import FloLoader from 'components/loader/FloLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const LoginPage = lazy(() => import('features/login/LoginPage'));
const VerificationPage = lazy(() => import('features/login/VerificationPage'));
const ForgotPassPage = lazy(() => import('features/login/ForgotPassPage'));

Sentry.init({
  dsn: 'https://b6afd66450d943028b7f2b7cec5e5878@o426244.ingest.sentry.io/4505048673288192',
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
});

library.add(far);
library.add(fas);
library.add(fad);

Amplify.configure(awsAmplifyConfig);

const TOAST_OPTIONS = {
  // Define default options
  duration: 100000,
  icon: <FontAwesomeIcon className="warnIcon" icon={['fas', 'triangle-exclamation']} />,
  style: {
    background: '#ffffff',
    border: '1px solid #ffb600',
    color: '#ffb600',
    borderRadius: '3px',
    boxShadow: 'none',
  },

  // Default options for specific types
  success: {
    duration: 5000,
    icon: <FontAwesomeIcon className="successIcon" icon={['fas', 'check-circle']} />,
    style: {
      background: '#ffffff',
      border: '1px solid #42bd41',
      color: '#42bd41',
      borderRadius: '3px',
      boxShadow: 'none',
    },
  },

  error: {
    duration: 10000,
    icon: <FontAwesomeIcon className="errorIcon" icon={['fas', 'times-circle']} />,
    style: {
      background: '#ffffff',
      border: '1px solid #f7626d',
      color: '#f7626d',
      borderRadius: '3px',
      boxShadow: 'none',
    },
  },
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Authenticator.Provider>
          <Toaster position="bottom-left" toastOptions={TOAST_OPTIONS}>
            {(toast) => <ToastBar toast={toast} style={{ maxWidth: '530px' }} />}
          </Toaster>
          <Suspense fallback={<FloLoader text={t('common.loading', 'Loading')} />}>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/verify" element={<VerificationPage />} />
              <Route path="/forgot_password" element={<ForgotPassPage />} />
              <Route
                path="*"
                element={
                  <PrivateRoute>
                    <DndProvider backend={HTML5Backend}>
                      <App />
                    </DndProvider>
                  </PrivateRoute>
                }
              />
            </Routes>
          </Suspense>
        </Authenticator.Provider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
