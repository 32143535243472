const dev = {
  STRIPE_KEY:
    'pk_test_51HTubzBshN3aFAPwmtHzE4qIeQ8lwFQry1d568W8eKvlKbOG3Oj1CS6qMqEYc1KhuIdSlui951utsDU4btCSgqA8002UROLOLm',
  AWS_REGION: 'ca-central-1',
  s3: {
    BUCKET: 'readywhen-tech-dev-admin-app-uploads',
    FILE_BUCKET: 'readywhen-tech-dev-go-company-files',
  },
  cognito: {
    USER_POOL_ID: 'ca-central-1_UKOL16kb2',
    APP_CLIENT_ID: '37coq7kqn6fdddm41uta58qcv6',
    IDENTITY_POOL_ID: 'ca-central-1:23c55750-eb9c-4767-8d79-ef75c857461f',
  },
  plans: {
    monthly: 'price_1Hq4wtBshN3aFAPwJfDUpDrW',
    yearly: 'price_1Hq4wuBshN3aFAPwSMTEwRn8',
    lifetime: 'price_1HthIlBshN3aFAPwz0P3be5k',
  },
  API_DOMAIN: 'https://api.dev.readywhen.ca/go',
  API_DOMAIN_ADMIN: 'https://api.dev.readywhen.ca/admin',
  TAX_RATE: 'txr_1HtgNiBshN3aFAPwN2bpXz06',
};

const bugfix = {
  STRIPE_KEY:
    'pk_test_51HTubzBshN3aFAPwmtHzE4qIeQ8lwFQry1d568W8eKvlKbOG3Oj1CS6qMqEYc1KhuIdSlui951utsDU4btCSgqA8002UROLOLm',
  AWS_REGION: 'ca-central-1',
  s3: {
    BUCKET: 'readywhen-tech-bugfix-flo-www',
    FILE_BUCKET: 'readywhen-tech-bugfix-go-company-files',
  },
  cognito: {
    USER_POOL_ID: 'ca-central-1_p3F2F9oLc',
    APP_CLIENT_ID: '3k7vn37di3hhkn2m30qf1plqs3',
    IDENTITY_POOL_ID: 'ca-central-1:68341170-5d07-472a-9676-bce24e1834ee',
  },
  plans: {
    monthly: 'price_1Hq4wtBshN3aFAPwJfDUpDrW',
    yearly: 'price_1Hq4wuBshN3aFAPwSMTEwRn8',
    lifetime: 'price_1HthIlBshN3aFAPwz0P3be5k',
  },
  API_DOMAIN: 'https://api.dev.readywhen.ca/go',
  API_DOMAIN_ADMIN: 'https://api.dev.readywhen.ca/admin',
  TAX_RATE: 'txr_1HtgNiBshN3aFAPwN2bpXz06',
};

const stg = {
  STRIPE_KEY:
    'pk_test_51HTubzBshN3aFAPwmtHzE4qIeQ8lwFQry1d568W8eKvlKbOG3Oj1CS6qMqEYc1KhuIdSlui951utsDU4btCSgqA8002UROLOLm',
  AWS_REGION: 'ca-central-1',
  s3: {
    BUCKET: 'readywhen-tech-stg-admin-app-uploads',
    FILE_BUCKET: 'readywhen-tech-stg-go-company-files',
  },
  cognito: {
    USER_POOL_ID: 'ca-central-1_OjG43j6kd',
    APP_CLIENT_ID: 'i5a63duu5qcatv5lli6tblr3o',
    IDENTITY_POOL_ID: 'ca-central-1:1db917aa-72c7-4750-9ae0-0c4f88a7a774',
  },
  plans: {
    monthly: 'price_1Hq4wtBshN3aFAPwJfDUpDrW',
    yearly: 'price_1Hq4wuBshN3aFAPwSMTEwRn8',
    lifetime: 'price_1HthIlBshN3aFAPwz0P3be5k',
  },
  API_DOMAIN: 'https://api.stg.goveyance.com/go',
  API_DOMAIN_ADMIN: 'https://api.stg.readywhen.ca/admin',
  TAX_RATE: 'txr_1HtgNiBshN3aFAPwN2bpXz06',
};

const offline = {
  STRIPE_KEY:
    'pk_test_51HTubzBshN3aFAPwmtHzE4qIeQ8lwFQry1d568W8eKvlKbOG3Oj1CS6qMqEYc1KhuIdSlui951utsDU4btCSgqA8002UROLOLm',
  AWS_REGION: 'ca-central-1',
  s3: {
    BUCKET: 'readywhen-tech-dev-admin-app-uploads',
    FILE_BUCKET: 'readywhen-tech-dev-go-company-files',
  },
  cognito: {
    USER_POOL_ID: 'ca-central-1_UKOL16kb2',
    APP_CLIENT_ID: '37coq7kqn6fdddm41uta58qcv6',
    IDENTITY_POOL_ID: 'ca-central-1:23c55750-eb9c-4767-8d79-ef75c857461f',
  },
  plans: {
    monthly: 'price_1Hq4wtBshN3aFAPwJfDUpDrW',
    yearly: 'price_1Hq4wuBshN3aFAPwSMTEwRn8',
    lifetime: 'price_1HthIlBshN3aFAPwz0P3be5k',
  },
  API_DOMAIN: 'http://localhost:4002',
  API_DOMAIN_ADMIN: 'http://localhost:4001',
  TAX_RATE: 'txr_1HtgNiBshN3aFAPwN2bpXz06',
};

const prod = {
  STRIPE_KEY:
    'pk_live_51HTubzBshN3aFAPw7DhgSlOnQbyYjsOcaYnh1AMs1g0rDaRx7xAoeZ3ObwhN5iPLAa1QW13qoF1I787jWExrcF5S00J2ew8Yr4',
  AWS_REGION: 'ca-central-1',
  s3: {
    BUCKET: 'readywhen-tech-prod-admin-app-uploads',
    FILE_BUCKET: 'readywhen-tech-prod-go-company-files',
  },
  cognito: {
    USER_POOL_ID: 'ca-central-1_63N7fbZY1',
    APP_CLIENT_ID: '7am9dn8o9ebjeeeqmut956t6qp',
    IDENTITY_POOL_ID: 'ca-central-1:1d256693-12ea-4cd2-ad8b-8da321856fea',
  },
  plans: {
    monthly: 'price_1HuXa3BshN3aFAPwKf9O7Jak',
    yearly: 'price_1HuXa3BshN3aFAPwxOd2yTMe',
    lifetime: 'price_1HuXa3BshN3aFAPwoZOa90Li',
  },
  API_DOMAIN: 'https://api.app.readywhen.ca/go',
  API_DOMAIN_ADMIN: 'https://api.app.readywhen.ca/admin',
  TAX_RATE: 'txr_1HuXj8BshN3aFAPwdstOgUx3',
};

// Default to dev if not set
let config;

switch (import.meta.env.MODE) {
  case 'production':
    config = prod;
    break;
  case 'offline':
    config = offline;
    break;
  case 'staging':
    config = stg;
    break;
  case 'bugfix':
    config = bugfix;
    break;
  default:
    config = dev;
}

export default {
  ...config,
};
