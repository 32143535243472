import qs from 'qs';
import type { Office } from '../offices/officesApi.types';
import type { Person } from './personsApi.types';

const buildTypeQuery = (type: string | string[] | undefined) => {
  if (!type) return '';

  if (Array.isArray(type)) {
    const queryStr = qs.stringify({ type }, { encode: false, indices: false });
    return `&${queryStr}`;
  }

  return `&type=${encodeURIComponent(type)}`;
};

export const getPersonsUrl = ({
  companyId,
  type,
  subType,
  search,
  officeId,
  limit = '20',
  page = '1',
  sortBy = 'name',
  isCorporation,
  scope,
}: {
  companyId: string;
  type?: string | string[];
  subType?: string;
  search?: string;
  officeId?: string;
  isCorporation?: boolean;
  limit?: string;
  page?: string;
  sortBy?: string;
  scope?: string;
}) =>
  `/companies/${companyId}/persons?limit=${limit}&page=${page}&sort_by=${sortBy}${buildTypeQuery(
    type
  )}${subType ? `&subType=${encodeURIComponent(subType)}` : ''}${
    search ? `&search=${encodeURIComponent(search)}` : ''
  }${officeId ? `&office_id=${encodeURIComponent(officeId)}` : ''}${
    isCorporation ? '&is_corporation=true' : ''
  }${scope ? `&scope=${scope}` : ''}`;

export const getPersonsByMatterIdUrl = ({
  companyId,
  matterId,
  type,
}: {
  companyId: string;
  matterId: string;
  type?: string;
}) => {
  if (type) {
    return `/companies/${companyId}/matters/${matterId}/persons?type=${type}`;
  }

  return `/companies/${companyId}/matters/${matterId}/persons`;
};

export const patchPersonsByMatterIdUrl = ({
  companyId,
  matterId,
  type,
}: {
  companyId: string;
  matterId: string;
  type: string;
}) => {
  return `/companies/${companyId}/matters/${matterId}/persons?person_type=${type}`;
};

export const getPersonsTenanciesByMatterIdUrl = ({
  companyId,
  matterId,
  type,
}: {
  companyId: string;
  matterId: string;
  type?: string;
}) => {
  if (type) {
    return `/companies/${companyId}/matters/${matterId}/persons/tenancies?type=${type}`;
  }

  return `/companies/${companyId}/matters/${matterId}/persons/tenancies`;
};

export const patchPersonsTenanciesByMatterIdUrl = ({
  companyId,
  matterId,
  type,
}: {
  companyId: string;
  matterId: string;
  type: string;
}) => {
  return `/companies/${companyId}/matters/${matterId}/persons/tenancies?person_type=${type}`;
};

export const getPersonByIdUrl = ({
  companyId,
  personId,
}: {
  companyId: string;
  personId: string;
}) => `/companies/${companyId}/persons/${personId}`;

export const getPersonByIdMatterUrl = ({
  companyId,
  personId,
  matterId,
  type,
}: {
  companyId: string;
  personId: string;
  matterId: string;
  type?: string;
}) => {
  if (type) {
    return `/companies/${companyId}/matters/${matterId}/persons/${personId}?person_type=${type}`;
  }

  return `/companies/${companyId}/matters/${matterId}/persons/${personId}`;
};

export const getPersonsByOfficeIdUrl = ({
  companyId,
  officeId,
}: {
  companyId: string;
  officeId: string;
}) => `/companies/${companyId}/offices/${officeId}/persons`;

export const buildPersonObject = (person: Person) => ({
  officeId: person?.officeId || person?.office?.id || '',
  personId: person?.id || '',
  suite: person?.office?.location?.suite || '',
  address: person?.office?.location?.address || '',
  city: person?.office?.location?.city || '',
  postalCode: person?.office?.location?.postalCode || '',
  state: person?.office?.location?.state || '',
  country: person?.office?.location?.country || '',
  phoneNumber:
    person?.phoneNumber ||
    person?.office?.location?.phone ||
    person?.MatterPerson?.metadata?.phoneNumber ||
    '',
  fax: person?.fax || person?.office?.location?.fax || person?.MatterPerson?.metadata?.fax || '',
  email:
    person?.email || person?.office?.location?.email || person?.MatterPerson?.metadata?.email || '',
  companyOffice: person?.office?.metadata?.companyOffice || false,
});

export const buildOfficeObject = (office: Office) => ({
  officeId: office?.id || '',
  suite: office?.location?.suite || '',
  address: office?.location?.address || '',
  city: office?.location?.city || '',
  postalCode: office?.location?.postalCode || '',
  state: office?.location?.state || '',
  country: office?.location?.country || '',
  phoneNumber: office?.location?.phone || office?.MatterOffice?.metadata?.phoneNumber || '',
  fax: office?.location?.fax || office?.MatterOffice?.metadata?.fax || '',
  email: office?.location?.email || office?.MatterOffice?.metadata?.email || '',
  name: office?.PropertyOffice?.metadata?.name || '',
  commission: office?.MatterOffice?.commission?.commission || '',
  gst: office?.MatterOffice?.commission?.gst || '',
  totalCommission: office?.MatterOffice?.commission?.totalCommission || '',
  showOnOtp: office?.MatterOffice?.commission?.showOnOtp || false,
  companyOffice: office?.metadata?.companyOffice || false,
  companyId: office?.companyId || '',
});

export const buildOfficePersonObject = (office: Office, person: Person) => {
  const { email, ...rest } = buildOfficeObject(office);

  return {
    ...rest,
    personId: person?.id || '',
    email:
      person?.MatterPerson?.metadata?.email ||
      office?.MatterOffice?.metadata?.email ||
      person?.email ||
      person?.office?.location?.email ||
      email,
  };
};

export const getPersonMatchUrl = ({
  companyId,
  matterId,
}: {
  companyId: string;
  matterId: string;
}) => {
  return `/companies/${companyId}/matters/${matterId}/persons/matches`;
};

export const getPersonUrl = ({ companyId, matterId }: { companyId: string; matterId: string }) => {
  return `/companies/${companyId}/matters/${matterId}/persons`;
};
