import FloLoader from 'components/loader/FloLoader';
import { lazy, Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { PAGE_URLS } from 'utils/urls';
import { useAuth } from 'hooks';

const TopNav = lazy(() => import('components/topNav/TopNav'));
const MattersPage = lazy(() => import('features/matters/MattersPage'));
const PropertyPage = lazy(() => import('features/property/PropertyPage'));
const SellersPage = lazy(() => import('features/sellers/SellersPage'));
const DebitCreditPage = lazy(() => import('features/debitCredit/DebitCreditPage'));
const BuyersPage = lazy(() => import('features/buyers/BuyersPage'));
const AccountSettingsPage = lazy(() => import('features/accountSettings/AccountSettingsPage'));
const ManageUsersPage = lazy(() => import('features/manageUsers/ManageUsersPage'));
const DetailsPage = lazy(() => import('features/details/DetailsPage'));
const UserProfilePage = lazy(() => import('features/userProfile/UserProfilePage'));
const DocumentsPage = lazy(() => import('features/documents/DocumentsPage'));
const ChargesPage = lazy(() => import('features/charges/ChargesPage'));
const TitleSearchPage = lazy(() => import('features/titleSearch/TitleSearchPage'));
const WebFilingPage = lazy(() => import('features/webFiling/WebFilingPage'));
const LotrPage = lazy(() => import('features/lotrFiling/LotrPage'));
const AccountsPage = lazy(() => import('features/accounts/AccountsPage'));
const AccountLibrary = lazy(() => import('features/accounts/accountLibrary/AccountLibrary'));
const TrustAccountsPage = lazy(() => import('features/trustAccounts/TrustAccountsPage'));
const AdjustmentLibrary = lazy(() => import('features/adjustmentLibrary/AdjustmentLibrary'));
const DocumentGroup = lazy(() => import('features/documentGroup/DocumentGroup'));
const DebitCreditLibrary = lazy(() => import('features/debitCreditLibrary/DebitCreditLibrary'));
const ChecklistLibrary = lazy(() => import('features/checklistLibrary/ChecklistLibrary'));
const ContactsPage = lazy(() => import('features/contacts/ContactsPage'));
const CalendarPage = lazy(() => import('features/calendar/CalendarPage'));
const InsurancePage = lazy(() => import('features/insurance/InsurancePage'));
const MortgagePage = lazy(() => import('features/mortgage/MortgagePage'));
const BorrowersPage = lazy(() => import('features/borrowers/BorrowersPage'));
const AdjustmentsPage = lazy(() => import('features/adjustments/AdjustmentsPage'));
const Notes = lazy(() => import('features/notes/Notes'));
const StatementOfAdjustments = lazy(
  () => import('features/statementOfAdjustments/StatementOfAdjustments')
);
const TrustPage = lazy(() => import('features/trust/TrustPage'));
const TitleInsurancePage = lazy(() => import('features/titleInsurance/TitleInsurancePage'));
const StewartCallback = lazy(() => import('features/titleInsurance/v2/StewartCallback'));
const TasksPage = lazy(() => import('features/tasks/TasksPage'));
const UndertakingsPage = lazy(() => import('features/undertakings/UndertakingsPage'));
const AdvancedSearchPage = lazy(() => import('features/advancedSearch/AdvancedSearchPage'));
const UndertakingsLibraryPage = lazy(
  () => import('features/undertakingsLibrary/UndertakingsLibrary')
);

// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';
import styles from './app.module.css';
import { updateTemporaryCredentials } from 'utils/auth';

function Layout() {
  const [cred, setCred] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    updateTemporaryCredentials().then(() => {
      setCred(true);
    });
  }, []);

  return cred ? (
    <div className={styles.app}>
      <TopNav />
      <Suspense fallback={<FloLoader text={t('common.loading')} />}>
        <Outlet />
      </Suspense>
    </div>
  ) : (
    <FloLoader text={t('common.authenticating')} />
  );
}

function App() {
  const { isUserAdmin } = useAuth();

  return (
    <Routes>
      <Route path={PAGE_URLS.baseurl} element={<Layout />}>
        <Route path={PAGE_URLS.baseurl} element={<Navigate replace to={PAGE_URLS.matters} />} />
        <Route path={PAGE_URLS.matters} element={<MattersPage />} />
      </Route>

      <Route path={PAGE_URLS.oauth} element={<Layout />}>
        <Route path={PAGE_URLS.stewart} element={<StewartCallback />} />
      </Route>

      <Route path={PAGE_URLS.matters} element={<Layout />}>
        <Route path={PAGE_URLS.titleSearch} element={<TitleSearchPage />} />
        <Route path={PAGE_URLS.notes} element={<Notes />} />
        <Route path={PAGE_URLS.details} element={<DetailsPage />} />
        <Route path={PAGE_URLS.property} element={<PropertyPage />} />
        <Route path={PAGE_URLS.sellers} element={<SellersPage />} />
        <Route path={PAGE_URLS.buyers} element={<BuyersPage />} />
        <Route path={PAGE_URLS.charges} element={<ChargesPage />} />
        <Route path={PAGE_URLS.trust} element={<TrustPage isSoluno={false} />} />
        <Route path={PAGE_URLS.soluno} element={<TrustPage isSoluno />} />
        <Route path={PAGE_URLS.accounts} element={<AccountsPage />} />
        <Route path={PAGE_URLS.debitCredit} element={<DebitCreditPage />} />
        <Route path={PAGE_URLS.webFiling} element={<WebFilingPage />} />
        <Route path={PAGE_URLS.lotrFiling} element={<LotrPage />} />
        <Route path={PAGE_URLS.documents} element={<DocumentsPage />} />
        <Route path={PAGE_URLS.mortgage} element={<MortgagePage />} />
        <Route path={PAGE_URLS.borrowers} element={<BorrowersPage />} />
        <Route path={PAGE_URLS.insurance} element={<InsurancePage />} />
        <Route path={PAGE_URLS.adjustments} element={<AdjustmentsPage />} />
        <Route path={PAGE_URLS.undertakings} element={<UndertakingsPage />} />
        <Route path={PAGE_URLS.statementOfAdjustments} element={<StatementOfAdjustments />} />
        <Route path={PAGE_URLS.titleInsurance} element={<TitleInsurancePage />} />
        <Route path="*" element={<Navigate to={PAGE_URLS.matters} replace />} />
      </Route>

      <Route path={PAGE_URLS.users} element={<Layout />}>
        <Route path={PAGE_URLS.userProfile} element={<UserProfilePage fullWidth />} />
        {isUserAdmin && (
          <Route path={PAGE_URLS.accountSettings} element={<AccountSettingsPage fullWidth />} />
        )}
        {isUserAdmin && (
          <Route path={PAGE_URLS.manageUsers} element={<ManageUsersPage fullWidth />} />
        )}
        {isUserAdmin && (
          <Route path={PAGE_URLS.accountLibrary} element={<AccountLibrary fullWidth />} />
        )}
        {isUserAdmin && (
          <Route path={PAGE_URLS.trustAccounts} element={<TrustAccountsPage fullWidth />} />
        )}
        {isUserAdmin && (
          <Route path={PAGE_URLS.adjustmentLibrary} element={<AdjustmentLibrary fullWidth />} />
        )}
        {isUserAdmin && (
          <Route path={PAGE_URLS.debitCreditLibrary} element={<DebitCreditLibrary fullWidth />} />
        )}
        {isUserAdmin && (
          <Route path={PAGE_URLS.documentGroups} element={<DocumentGroup fullWidth />} />
        )}
        {isUserAdmin && (
          <Route path={PAGE_URLS.checklistLibrary} element={<ChecklistLibrary fullWidth />} />
        )}
        {isUserAdmin && (
          <Route
            path={PAGE_URLS.undertakingsLibrary}
            element={<UndertakingsLibraryPage fullWidth />}
          />
        )}
      </Route>

      <Route path={PAGE_URLS.contacts} element={<Layout />}>
        <Route path={PAGE_URLS.contacts} element={<Navigate replace to={PAGE_URLS.clients} />} />
        <Route path={PAGE_URLS.clients} element={<ContactsPage fullWidth pageName="clients" />} />
        <Route path={PAGE_URLS.legal} element={<ContactsPage fullWidth pageName="legal" />} />
        <Route
          path={PAGE_URLS.realEstateOffices}
          element={<ContactsPage fullWidth pageName="real-estate-offices" />}
        />
        <Route
          path={PAGE_URLS.insuranceAgencies}
          element={<ContactsPage fullWidth pageName="insurance-agencies" />}
        />
        <Route
          path={PAGE_URLS.mhrManagementCompany}
          element={<ContactsPage fullWidth pageName="mhr-management-company" />}
        />
        <Route path={PAGE_URLS.lenders} element={<ContactsPage fullWidth pageName="lenders" />} />
        <Route
          path={PAGE_URLS.strataOffices}
          element={<ContactsPage fullWidth pageName="strata-offices" />}
        />
        <Route
          path={PAGE_URLS.taxationAuthority}
          element={<ContactsPage fullWidth pageName="taxation-authority" />}
        />
      </Route>

      <Route path={PAGE_URLS.calendar} element={<Layout />}>
        <Route path={PAGE_URLS.calendar} element={<CalendarPage />} />
      </Route>

      <Route path={PAGE_URLS.tasks} element={<Layout />}>
        <Route path={PAGE_URLS.tasks} element={<TasksPage />} />
      </Route>

      <Route path={PAGE_URLS.search} element={<Layout />}>
        <Route path={PAGE_URLS.search} element={<AdvancedSearchPage />} />
      </Route>
    </Routes>
  );
}

export default App;
