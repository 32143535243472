import type { EntityId } from '@reduxjs/toolkit';

export enum Tags {
  AUTO_COMPLETE = 'AutoComplete',
  ACCOUNT_LIBRARY = 'AccountLibrary',
  CUSTOMER = 'Customer',
  CLIENT = 'Client',
  MATTER = 'Matter',
  CALENDAR = 'Calendar',
  MATTER_BY_RANGE = 'MatterByRange',
  PERSON = 'Person',
  OFFICE = 'Office',
  CHARGE = 'Charge',
  MATTER_SEARCH = 'MATTER_SEARCH',
  LIST = 'LIST',
  TITLE_SEARCH = 'TitleSearch',
  TITLE_SEARCH_ORDER = 'TitleSearchOrder',
  PROPERTY = 'Property',
  FORM_TYPE = 'FormType',
  PACKAGE = 'Package',
  LOTR_PACKAGE = 'LotrPackage',
  DOCUMENT = 'Document',
  SELLER = 'Seller',
  BUYER = 'Buyer',
  BUYER_TENANCY = 'BuyerTenancy',
  CHARGE_DEBIT_CREDIT = 'ChargeDebitCredit',
  TEMPLATE = 'Template',
  FEE = 'Fee',
  FEE_MATTER = 'FeeMatter',
  DISBURSEMENT = 'Disbursement',
  DISBURSEMENT_MATTER = 'DisbursementMatter',
  USER = 'User',
  MANAGE_USER = 'ManageUser',
  ACCOUNT_SETTING = 'AccountSetting',
  LIBRARY_DEBIT_CREDIT = 'LibraryDebitCredit',
  SEARCH = 'Search',
  GROUP_TEMPLATES = 'GroupTemplates',
  GROUP_TEMPLATES_SEARCH = 'GroupTemplatesSearch',
  GROUP_TEMPLATES_SEARCH_MATTER = 'GroupTemplatesSearchMatter',
  INSURANCE = 'Insurance',
  MORTGAGE = 'Mortgage',
  MORTGAGE_PRODUCT = 'MortgageProduct',
  MORTGAGE_PRODUCT_TERMS = 'MortgageProductTerms',
  GUARANTOR = 'Guarantor',
  BORROWER = 'Borrower',
  BORROWER_TENANCY = 'BorrowerTenancy',
  ADJUSTMENTS_LIBRARY = 'AdjustmentsLibrary',
  ADJUSTMENTS = 'Adjustments',
  CHECKLISTS = 'Checklists',
  CHECKLIST_TEMPLATES = 'ChecklistTemplates',
  CHECKLIST_TASKS = 'ChecklistTasks',
  STATEMENT_ADJUSTMENTS = 'StatementAdjustments',
  TRUST = 'Trust',
  TRUST_ACCOUNTS = 'TrustAccounts',
  CONTACT = 'Contact',
  LENDER_PRODUCT = 'LenderProduct',
  CTIC_TITLE_INSURANCE = 'CticTitleInsurance',
  CTIC_URL = 'CticTitleURL',
  CTIC_DOCUMENTS = 'CticDocuments',
  MORTGAGE_LENDER = 'MortgageLender',
  NOTIFICATION = 'Notification',
  NOTES = 'Notes',
  TITLE_INSURANCE = 'TitleInsurance',
  TITLE_INSURANCE_URL = 'TitleInsuranceUrl',
  TASKS = 'Tasks',
  MATTER_TASKS = 'MatterTasks',
  UNDERTAKING_TEMPLATE = 'UndertakingTemplate',
  UNDERTAKING = 'Undertaking',
  IMPORT = 'Import',
}

export const providesList = <R extends { id: string | number }[], T extends string>(
  resultsWithIds: R | undefined,
  tagType: T
) => {
  return Array.isArray(resultsWithIds)
    ? [{ type: tagType, id: 'LIST' }, ...resultsWithIds.map(({ id }) => ({ type: tagType, id }))]
    : [{ type: tagType, id: 'LIST' }];
};

export const providesListEntity = <R extends EntityId[], T extends string>(
  ids: R | undefined,
  tagType: T
) => {
  return Array.isArray(ids)
    ? [{ type: tagType, id: 'LIST' }, ...ids.map((id) => ({ type: tagType, id }))]
    : [{ type: tagType, id: 'LIST' }];
};

export const providesTag = <I extends string | number, T extends string>(id: I, tagType: T) => {
  return [{ type: tagType, id }];
};
