import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'app/rootReducer';

const searchAdapter = createEntityAdapter();

export const searchSlice = createSlice({
  name: 'search',
  initialState: searchAdapter.getInitialState({
    loading: false,
    error: false,
    results: [],
  }),
  reducers: {
    setSearchResults(state, action) {
      state.loading = false;
      const { payload } = action;
      if (payload) {
        state.results = payload;
      }
      state.error = false;
    },
  },
});

export const { setSearchResults } = searchSlice.actions;

export const searchSelectors = (state: RootState) => state.search;

export default searchSlice.reducer;
