import { Dimmer, Loader } from 'semantic-ui-react';

interface LoaderProps {
  text: string;
}

const FloLoader = ({ text }: LoaderProps) => {
  return (
    <Dimmer active inverted>
      <Loader active>{text}</Loader>
    </Dimmer>
  );
};

export default FloLoader;
