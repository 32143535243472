import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'app/rootReducer';

interface AccordionStateProps {
  activeIndexes: Array<number>;
  nestedActiveIndexes: Record<number, Array<number>>;
}

const initialState: AccordionStateProps = {
  activeIndexes: [0, 1, 2, 3],
  nestedActiveIndexes: { 0: [0], 1: [], 2: [] },
};

export const accordionSlice = createSlice({
  name: 'accordion',
  initialState,
  reducers: {
    setActiveIndex: (state, action: PayloadAction<number>) => {
      if (state.activeIndexes.indexOf(action.payload) > -1) {
        state.activeIndexes = state.activeIndexes.filter((index) => index !== action.payload);
      } else {
        state.activeIndexes.push(action.payload);
      }
    },
    setActiveIndexes: (state, action: PayloadAction<Array<number>>) => {
      const indexes = new Set([...state.activeIndexes, ...action.payload]);
      state.activeIndexes = Array.from(indexes);
    },
    setNestedIndex: (state, action: PayloadAction<number>) => {
      state.nestedActiveIndexes = { [action.payload]: [0], ...state.nestedActiveIndexes };
    },
    setNestedItemIndex: (
      state,
      action: PayloadAction<{ parentIndex: number; childIndex: number }>
    ) => {
      if (!state.nestedActiveIndexes[action.payload.parentIndex]) {
        state.nestedActiveIndexes = { [action.payload.parentIndex]: [] };
      }

      if (
        state.nestedActiveIndexes[action.payload.parentIndex].indexOf(action.payload.childIndex) >
        -1
      ) {
        state.nestedActiveIndexes[action.payload.parentIndex] = state.nestedActiveIndexes[
          action.payload.parentIndex
        ].filter((index) => index !== action.payload.childIndex);
      } else {
        state.nestedActiveIndexes[action.payload.parentIndex].push(action.payload.childIndex);
      }
    },
    resetIndexes: (state) => {
      state.activeIndexes = [0, 1, 2, 3];
    },
    setActiveMultiplePropertyNestedIndex: (state) => {
      state.activeIndexes = [];
      state.nestedActiveIndexes = {};
    },
    setActiveSinglePropertyNestedIndex: (state) => {
      state.activeIndexes = [0];
      state.nestedActiveIndexes = { 0: [0] };
    },
  },
});

export const accordionSelectors = (state: RootState) => state.accordion;

export const {
  setActiveIndexes,
  setActiveIndex,
  resetIndexes,
  setNestedIndex,
  setNestedItemIndex,
  setActiveMultiplePropertyNestedIndex,
  setActiveSinglePropertyNestedIndex,
} = accordionSlice.actions;

export default accordionSlice.reducer;
