import { type BaseQueryFn, retry } from '@reduxjs/toolkit/dist/query';
import { Signer } from '@aws-amplify/core';
import AWS from 'aws-sdk';
import type { AxiosError, AxiosRequestConfig } from 'axios';
import axios from 'axios';
import { updateTemporaryCredentials } from 'utils/auth';
import config from '../../configs/config';
import { store } from 'app/store';
import { Auth } from 'aws-amplify';
import { Mutex } from 'async-mutex';

const mutex = new Mutex();

async function logout() {
  await Auth.signOut();
  sessionStorage.clear();
  store.dispatch({ type: 'login/logout' });
}

async function getSignedRequest(
  url: string,
  method: string,
  data?: unknown
): Promise<AxiosRequestConfig | null> {
  const { credentials } = AWS.config;
  if (credentials) {
    const accessInfo = {
      access_key: credentials.accessKeyId,
      secret_key: credentials.secretAccessKey,
      session_token: credentials.sessionToken,
    };

    const serviceInfo = {
      region: config.AWS_REGION,
      service: 'execute-api',
    };

    const request = Signer.sign(
      {
        method,
        url,
        data: JSON.stringify(data),
      },
      accessInfo,
      serviceInfo
    );
    return request;
  }

  return null;
}

async function setSignedHeaders(reqConfig: AxiosRequestConfig): Promise<AxiosRequestConfig> {
  const { url, method, data } = reqConfig;

  if (url && method) {
    return getSignedRequest(url, method.toUpperCase(), data || undefined).then((request) => {
      if (request) {
        delete request.headers?.host; //prevent unsafe header warning
        reqConfig.headers = { ...reqConfig.headers, ...request.headers };
      }
      return reqConfig;
    });
  }
  return Promise.resolve(reqConfig);
}

export async function signedAxiosRequest(aConfig: AxiosRequestConfig, isRetry?: boolean) {
  const signed = await setSignedHeaders(aConfig);
  const { url = '', method, data, ...signedConfig } = signed;

  let result;

  // using axios method aliases for easy testing with sypOn
  switch (method) {
    case 'GET':
      result = await axios.get(url, signedConfig);
      break;
    case 'PUT':
      result = await axios.put(url, data, signedConfig);
      break;
    case 'POST':
      result = await axios.post(url, data, signedConfig);
      break;
    case 'DELETE':
      result = await axios.delete(url, { ...signedConfig, data });
      break;
    case 'PATCH':
      result = await axios.patch(url, data, signedConfig);
      break;
    case 'HEAD':
      result = await axios.head(url, signedConfig);
      break;
    case 'OPTIONS':
      result = await axios.options(url, signedConfig);
      break;
    default:
      result = await axios.request(signed);
      break;
  }

  return {
    data: result?.data,
    meta: {
      status: result?.status,
      headers: result?.headers,
      ...(isRetry ? { retry: true } : {}),
    },
  };
}

interface RetryConfig extends AxiosRequestConfig {
  meta: {
    retry: boolean;
    status: number;
  };
}

const constructErrorData = (err: AxiosError) => {
  const { message, response, request } = err;
  const data = response?.data;
  const responseURL = request?.responseURL;

  const { data: resData, message: resMessage } =
    (data as { data: Record<string, string[]>; message: string }) || {};

  if (responseURL.includes('webFiling')) {
    let webFilingError = '';
    const list: string[] = [];
    if (Object.keys(resData).length > 0) {
      for (const key in resData) {
        if (resData.hasOwnProperty(key)) {
          resData[key].forEach((value: string) => {
            console.log(value);
            list.push(`• ${value}\n`);
          });
        }
      }
      const formattedList = list.join('');
      webFilingError = `${resMessage}:\n ${formattedList}`;
    } else {
      webFilingError = resMessage;
    }
    return webFilingError;
  }

  if (Array.isArray(resData)) {
    const lists = `${resData.map((r) => `• ${r}\n`).join('')}`;
    if (resMessage) {
      return `${resMessage}:\n ${lists}`;
    }
    return lists;
  }

  if (resMessage) {
    return resMessage;
  }

  return message;
};

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: `${config.API_DOMAIN}` }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      headers?: AxiosRequestConfig['headers'];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params, headers }) => {
    const aConfig = { url: baseUrl + url, method, data, params, headers };

    await mutex.waitForUnlock();

    const RETRY_CACHE = 'RetryCounters';

    let result = {
      data,
    };

    try {
      result = await signedAxiosRequest(aConfig);
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      const error = {
        status: err.response?.status,
        data: constructErrorData(err),
      };

      const cache = sessionStorage.getItem(RETRY_CACHE);
      const counter = cache ? JSON.parse(cache) : {};

      // bail out of re-tries immediately if unauthorized,
      // because we know successive re-retries would be redundant
      if (
        err?.response?.status === 401 ||
        (err?.response?.data as { message: string })?.message ===
          'The security token included in the request is invalid.' ||
        counter?.[aConfig.url] > 1
      ) {
        await logout();
        retry.fail(error);
      }

      if (err?.response?.status === 403) {
        if (counter[aConfig.url] >= 3) {
          sessionStorage.removeItem('TempCred');
          await logout();
          retry.fail(error);
        }

        if (!mutex.isLocked()) {
          const release = await mutex.acquire();
          try {
            const refreshResult = await updateTemporaryCredentials(true);
            if (refreshResult) {
              // retry the initial query
              result = await signedAxiosRequest(aConfig, true);
              sessionStorage.setItem(
                RETRY_CACHE,
                JSON.stringify({
                  ...counter,
                  [aConfig.url]: ++counter[aConfig.url] || 0,
                })
              );
              if (
                (result as RetryConfig).meta?.status === 403 &&
                (result as RetryConfig).meta?.retry
              ) {
                await logout();
                retry.fail(error);
              }
            } else {
              await logout();
            }
          } finally {
            release();
          }
        } else {
          await mutex.waitForUnlock();
          result = await signedAxiosRequest(aConfig);
        }

        return result;
      }

      return { error };
    }

    return result;
  };

export default axiosBaseQuery;
