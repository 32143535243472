import type { Branch } from 'app/services/accountSettings/accountSettingApi.types';
import type { SearchItem } from 'components/autocompleteInput/AutocompleteInput.types';
import { formatAddressForAutocomplete } from 'utils/stringUtils';

export const getBranchesData = (branches: Branch[]) => {
  return branches.map((branch) => {
    const { suite, address, city, state, country, postalCode, email, phone, fax } =
      branch?.location ?? {};
    return {
      officeId: branch.id,
      id: branch.id,
      description: formatAddressForAutocomplete({ address, city, suite, state, postalCode }),
      display: branch.name,
      title: branch.name,
      suite,
      address,
      city,
      country,
      state,
      postalCode,
      email,
      phoneNumber: phone,
      fax,
      firstName: '',
      lastName: '',
      companyId: branch.companyId,
      companyOffice: !!branch.companyId,
    };
  });
};

export const getOfficesData = (offices: SearchItem[], withBranches: boolean) => {
  if (withBranches) {
    return offices.reduce((acc: SearchItem[], office: SearchItem) => {
      if (office.branches) {
        return [...acc, ...getBranchesData(office.branches)];
      }

      return [...acc, office];
    }, []);
  }

  return offices;
};
